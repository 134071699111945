<script>
  import API from '@/api/modules/App/ky';
  import { mapGetters } from 'vuex';

  export default {
    async asyncData({ store, $http, params }) {
      const { code } = params;
      const data = await $http.post(API.GET_INFO, {
        code,
      });
      store.commit('app/ky/SET_CODE_DATA', data);
      console.log(data);
    },
    computed: {
      ...mapGetters('app/ky', ['code_data']),
    },
    data() {
      return {
        text: '',
        btnDesc: '',
      };
    },
    mounted() {
      if (this.code_data && this.code_data.jump_type) {
        if (this.code_data.jump_type == 'app') {
          this.text = '点击下方按钮打开快音APP';
          this.btnDesc = '立即前往';
        } else if (this.code_data.jump_type == 'wxMiniProgram') {
          this.text = '点击下方按钮打开快音微信小程序';
          this.btnDesc = '立即前往';
        } else if (this.code_data.jump_type == 'http') {
          this.text = ' 点击下方按钮可打开快音活动页面';
          this.btnDesc = '立即前往';
        }
        window.location.href = this.code_data.jump_link;
      }
    },
    methods: {
      jump() {
        if (this.code_data && this.code_data.jump_type) window.location.href = this.code_data.jump_link;
      },
    },
  };
</script>

<template>
  <div class="center">
    <div class="font">{{ text }}</div>
    <div class="btn" @click="jump">{{ btnDesc }}</div>
  </div>
</template>

<style lang="less" scoped>
  .center {
    height: 100vh;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    .font {
      font-size: 0.4rem;
    }
    .btn {
      font-size: 0.3rem;
      margin-top: 0.6rem;
      height: 0.6rem;
      width: 3rem;
      text-align: center;
      background: #61bc6c;
      color: #fff;
      line-height: 0.6rem;
    }
  }
</style>
